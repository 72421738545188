<template>
    <GuideSteps :steps="steps">
        <template slot="header">
            {{ $t('MultivoucherSteps.header', { platformName }) }}
        </template>
    </GuideSteps>
</template>

<script>
    import GuideSteps from '@/components/guideSteps/GuideSteps';
    import Step from '@/models/GuideStep';

    export default {
        name: 'MultivoucherSteps',
        components: {
            GuideSteps
        },
        computed: {
            platformName() {
                let result = this.$store.state.globalMeta.shopName;
                if (result.indexOf("Multigutschein") === -1) {
                    result += "-Multigutschein";
                }
                return result;
            },
            steps() {
                return [
                    new Step(
                        require('@/assets/guideSteps/1.png'),
                        this.$t('MultivoucherSteps.step1')
                    ),
                    new Step(
                        require('@/assets/guideSteps/2.png'),
                        this.$t('MultivoucherSteps.step2', { platformName: this.platformName })
                    ),
                    new Step(
                        require('@/assets/guideSteps/3b.png'),
                        this.$t('MultivoucherSteps.step3')
                    )
                ]
            }
        }
    }
</script>

<style scoped>

</style>
