class GuideStep {
    /**
     * @constructor
     * @param {String} imageUrl
     * @param {String} text - string or html string
     */
    constructor (imageUrl, text) {
        Object.assign(this, {
            imageUrl,
            text
        })
    }
}

export default GuideStep;
