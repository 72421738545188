<template>
    <section class="guide-steps">
        <h2 class="heading">
            <slot name="header">
                {{ $t('GuideSteps.headerDefault') }}
            </slot>
        </h2>
        <div class="row">
            <div v-for="(step, index) in steps" :key="'step' + index" class="col-md box-wrapper">
                <div class="step text-center" :class="'step-' + (index + 1)">
                    <div class="number">{{ index + 1 }}</div>
                    <img v-if="step.imageUrl !== null" :src="step.imageUrl" alt="" class="image img-fluid" />
                    <div class="text two-lines" v-html="step.text"></div>
                </div>
            </div>
        </div>
        <slot></slot>
    </section>
</template>

<script>
import GuideStep from '@/models/GuideStep';

export default {
    name: 'GuideSteps',
    props: {
        steps: {
            type: Array,
            default() {
                return [
                    new GuideStep(require('@/assets/guideSteps/1.png'), this.$t('GuideSteps.step1')),
                    new GuideStep(require('@/assets/guideSteps/2.png'), this.$t('GuideSteps.step2')),
                    new GuideStep(require('@/assets/guideSteps/3b.png'), this.$t('GuideSteps.step3'))
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;
@use '@/styles/theming' as *;

.guide-steps {
    h2.heading {
        color: var(--body-color);
        display: block;
        padding: 20px 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
        width: 100%;
    }

    .step {
        background-color: var(--guide-steps-bg);

        .number {
            background-color: var(--guide-steps-num-bg);
            color: var(--guide-steps-num-color);
            border-radius: var(--guide-steps-num-border-radius);
            @include themify(schlemmerauszeit, firstclass) {
                // TODO: test whether positioning cannot be achieved easier
                top: themed('$guide-steps-num-top');
                left: themed('$guide-steps-num-left');
            }
        }

        min-height: 250px;
        position: relative;
        height: 100%;

        .image {
            padding: 15px 0 0;
        }

        .number {
            font-size: 30px;
            line-height: 1;
            padding: 4px 12px 5px;
            position: absolute;
        }

        .text {
            text-align: center;
            font-size: 18px;
            line-height: 22px;

            &.two-lines {
                padding: 15px 25px;
            }
        }
    }

    @include breakpoints.media-breakpoint-only(xs) {
        .box-wrapper:not(:last-child) {
            margin-bottom: variables.$grid-padding;
        }
    }
}
</style>
